/*.App {*/
/*  font-family: sans-serif;*/
/*  text-align: center;*/
/*}*/
/*.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="0.75em"]::before {*/
/*  content: "Small";*/
/*}*/

/*.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1em"]::before {*/
/*  content: "Normal";*/
/*}*/

/*.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1.5em"]::before {*/
/*  content: "Large";*/
/*}*/

/*.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="2.5em"]::before {*/
/*  content: "Huge";*/
/*}*/

/*.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before {*/
/*  content: "Small";*/
/*  font-size: 0.75em !important;*/
/*}*/

/*.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1em"]::before {*/
/*  content: "Normal";*/
/*  font-size: 1em !important;*/
/*}*/

/*.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {*/
/*  content: "Large";*/
/*  font-size: 1.5em !important;*/
/*}*/

/*.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before {*/
/*  content: "Huge";*/
/*  font-size: 2.5em !important;*/
/*}*/

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="6px"]::before {content: "6px";}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="8px"]::before {content: "8px";}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before {content: "10px";}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before {content: "12px";}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before {content: "14px";}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before {content: "16px";}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before {content: "18px";}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before {content: "20px";}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before {content: "22px";}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="6px"]::before {content: "6px";font-size: 6px !important;}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {content: "8px";font-size: 8px !important;}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {content: "10px";font-size: 10px !important;}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {content: "12px";font-size: 12px !important;}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {content: "14px";font-size: 14px !important;}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {content: "16px";font-size: 16px !important;}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {content: "18px";font-size: 18px !important;}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {content: "20px";font-size: 20px !important;}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {content: "22px";font-size: 22px !important;}

.ql-container
, .ql-editor {
  /*height: 500px;*/
}

*[quill__html]{
  display: none;
  width: 100%;
  margin: 0;
  background: rgb(29, 29, 29);
  box-sizing: border-box;
  color: rgb(204, 204, 204);
  outline: none;
  padding: 12px 15px;
  line-height: 24px;
  font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  position: absolute;
  top: 0;
  bottom: 0;
  border: none;
}

*[quill__html *= '-active-']{
  display: initial;
}
.ql-editor>p{
  line-height:1;
}

.ql-editor strong{
  font-weight:bold;
}

.ql-editor em {
  font-style: italic;
}